<template>
  <div class="container">
    <navbar></navbar>
    <div class="content p-b-20">
      <van-tabs
        v-model="tabIndex"
        color="#084CE7"
        title-active-color="#fff"
        title-inactive-color="#999"
        class="px-20"
        @change="tabChange"
      >
        <van-tab
          v-for="(item, index) in tabList"
          :key="index"
          :title="item.title"
        ></van-tab>
      </van-tabs>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="$t('public.pulling')"
        :loosing-text="$t('public.loosing')"
        :loading-text="$t('public.loading')"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.nomores')"
          :loading-text="$t('public.loading')"
          :offset="150"
          @load="onLoad"
        >
          <!-- 提现记录 -->
          <div class="m-t-20 px-20" v-if="tabIndex == 0">
            <div
              class="d-flex ai-center jc-between fs-lg box m-b-10 border_b"
              style="height: 54px"
              v-for="(item, index) in withList"
              :key="index"
            >
              <p class="col1 t-left">{{ item.created_at }}</p>
              <p class="fs-xl col2 t-center">
                <span class="t-color5">{{ item.amount | cutZero }}</span> UANG
              </p>
              <!-- 0未审核，1已审核待转币，2已驳回，4已转币，5转币失败，其他值都为待处理 -->
              <p
                class="col3 t-right"
                :class="{
                  't-color2':
                    item.status == 0 || item.status == 1 || item.status == 2,
                  't-color12': item.status == 4,
                  't-color13': item.status == 5,
                }"
              >
                {{ withStatus(item.status) }}
              </p>
            </div>
          </div>
          <!-- 收益记录 -->
          <div class="m-t-20 px-20" v-else>
            <div
              class="d-flex ai-center jc-between fs-lg box m-b-10 border_b"
              style="height: 54px"
              v-for="(item, index) in earnList"
              :key="index"
            >
              <p class="col1 t-left">{{ item.created_at }}</p>
              <p class="fs-xl col2 t-center">
                <span class="t-color5">{{ item.earnings | cutZero }}</span> ACDC
              </p>
              <p class="col3 t-right">
                {{
                  item.type == "dig"
                    ? $t("extract.income")
                    : $t("extract.reward")
                }}
              </p>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,

      tabIndex: 0,
      withList: [],
      earnList: [],
    };
  },
  computed: {
    tabList() {
      return [
        { index: 0, title: this.$t("extract.record") },
        { index: 1, title: this.$t("extract.record2") },
      ];
    },
  },
  mounted() {
    if (this.$route.query.i) {
      this.tabIndex = Number(this.$route.query.i);
    }
    // this.init();
  },
  methods: {
    withStatus(e) {
      // 0未审核，1已审核待转币，2已驳回，4已转币，5转币失败，其他值都为待处理
      if (e == 0) {
        return this.$t("extract.notreview");
      } else if (e == 1) {
        return this.$t("extract.transfer");
      } else if (e == 2) {
        return this.$t("extract.fail");
      } else if (e == 4) {
        return this.$t("extract.suc");
      } else if (e == 5) {
        return this.$t("extract.fails");
      } else {
        return this.$t("extract.review");
      }
    },
    tabChange(index) {
      this.tabIndex = index;
      this.finished = false;
      this.loading = true;
      this.pageNumber = 1;
      this.totalPage = 1;
      this.getList();
    },
    getList() {
      let that = this;
      this.$axios
        .get(
          this.tabIndex == 0 ? this.$api.withdraw_record : this.$api.earnrecord,
          {
            page: this.pageNumber,
            page_size: 8,
          }
        )
        .then((res) => {
          if (res.code == 200) {
            this.loading = false;
            this.refreshing = false;
            let arr = res.data.list;
            that.totalPage = res.data.totalPage; // 总条数
            if (arr == null || arr.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            this.tabIndex == 0
              ? this.withList.push(...arr)
              : this.earnList.push(...arr);
            // 如果当前页数>=总条数，不再触发滚动加载
            if (that.pageNumber >= that.totalPage) {
              that.finished = true;
            }
            // if (this.pageNumber == 1) {
            //   if (this.tabIndex == 0) {
            //     this.withList = [];
            //     this.withList = arr;
            //   } else {
            //     this.earnList = [];
            //     this.earnList = arr;
            //   }
            //   this.totalPage = res.data.totalPage;
            // } else {
            //   this.tabIndex == 0
            //     ? this.withList.push(...arr)
            //     : this.earnList.push(...arr);
            // }
          } else {
            this.loading = false;
            this.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      setTimeout(() => {
        this.finished = false;
        this.getList();
        this.pageNumber++;
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getList();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-tab {
  font-size: 13px;
}
/deep/ .van-tabs__nav {
  background-color: transparent !important;
}
/deep/ .van-tabs__line {
  bottom: 18px !important;
}
.border_b {
  border-bottom: 1px solid rgba($color: #9998c2, $alpha: 0.3);
}
.col1 {
  width: 40%;
}
.col2 {
  width: 30%;
}
.col3 {
  width: 30%;
}
</style>